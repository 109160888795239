@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1329px)";
@desktop: ~"(min-width: 1330px)";
@mobileNavColor: #ea1d25;
@castleDetailsColor: #ea1d25;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/";
@cloudUrlNoAuto: "https://files.bookingonline.co.uk/image/upload/themes/020/";

/*#region Fonts*/
.Lato, .BodyFont {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
}

.LatoBold {
	font-weight: 700;
	font-family: 'Lato', sans-serif;
}

.font-Bjola;

.Bjola {
	font-family: 'Bjola';
}
/*#endregion Fonts*/
html {
	font-size: 16px;
}

body {
	.BodyFont;
	font-size: 16px;
	background: #fff;
}

form {
	margin: 0 auto;
	overflow: hidden;
	position: relative;
}

#BodyContent {
	padding-top: 1px;

	@media @tablet {
	}
}

.textContent {
	padding: 0;

	@media @mobile {
		padding: 0 10px;
	}

	h1 {
		color: #e77c29;
		.section-title;
		visibility: hidden;



		@media @mobile {
			margin-top: 0;
		}
	}
}

.splitter.animated {

	.char {
		animation: slide-in 1s cubic-bezier(.5, 0, .5, 1) both;
		animation-delay: calc(60ms * var(--char-index));
	}
}

.section-title {
	.Bjola;
	font-size: 5vw;
	text-align: center;

	@media @mobile {
		font-size: 2.5rem;
	}

	@media @desktop {
		font-size: 54px;
	}
}

.nicefont {
	.LatoBold;
}

h1, h2, h3 {
}

.outer-wrapper {
	overflow: hidden;
	max-width: 100vw;
	position: relative;

	&--alt {
		.top-wrapper__city-back {
			transform: translateY(40%);
		}

		.top-wrapper__city-front {
			transform: translateY(50%);
		}

		.top-wrapper__starburst {
			transform: scale(2);
			top: -137%;
			left: 0;
		}

		.header__email {
			filter: drop-shadow(0 0 5px #fff);
		}

		.page-listings > h2 {
			display: none;
		}

		.category-links{
			margin-top:10px;
		}
		
	}

	&--homepage {
		@media @mobile {
			.top-wrapper__city-back {
				bottom: 15%;
				visibility: visible !important;
			}

			.top-wrapper__city-front {
				bottom: 13%;
				visibility: visible !important;
			}
		}
		/*.top-wrapper__city-blue {
			position: absolute;
			display: block;
			bottom: 0;
			left: 0;
			padding-bottom: 40%;
			background: #003670;
			width: 100%;
		}*/
	}
}

.container {
	position: relative;

	@media @mobile {
		margin: 0 auto 0;
		padding: 0;
	}

	@media @tablet {
		width: 100%;
		padding: 0 20px;
		margin: 0 auto 0;
	}

	@media @desktop {
		width: 1300px;
		margin: 0 auto 0;
	}
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
	padding-top: 53px;
}

#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	min-height: 5px;

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	@media @sm {
		display: block !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
		height:51px;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0% 0%;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
	position: relative;
	background-color: #0bc3ff;

	&__gradient {
		position: absolute;
		width: 100vw;
		max-width: 2130px;
		height: 100%;
		top: 0;
		.Gradient(#11bbff,#27d7ff);
	}

	&__curve {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding-bottom: 10%;
		mask: url("@{cloudUrl}curve-bottom-mask.svg") center bottom / contain;
		mask-size: 101% 100%;
		background: #e5eaff;

		&:before {
			.StickToEdges;
			background-repeat: repeat;
			background-image: url("@{cloudUrl}diagonal-line.svg");
			background-size: 10px;
			opacity: 0.05;
			transform: scaleX(-1);
			background-position: 0 10.7%;
		}
	}

	&__city-back {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding-bottom: 27%;
		background-position: top left;
		background-repeat: repeat-x;
		background-image: url("@{cloudUrlNoAuto}city-back.svg");
		background-size: auto 100%;

		@media @mobile {
			background-position: bottom left;
		}
	}

	&__city-front {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding-bottom: 20%;
		background-repeat: repeat-x;
		background-image: url("@{cloudUrlNoAuto}city-front.svg");
		background-size: auto 100%;


		@media @mobile {
			background-position: bottom left;
		}
	}

	&__city-blue {
		display: none; // shown on --homepage
	}

	&__starburst {
		position: absolute;
		top: -50%;
		left: -20%;
		width: 100%;
		padding-top: 100%;
		background-image: url("@{cloudUrlNoAuto}starburst.svg");
		opacity: 0;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		transform: translate(-40%,-40%);

		@media @mobile {
			transform: translateY(-23%);
			top: -11% !important;
			transform: scale(2);
		}
	}
}

.header {
	@width: 2757px;
	@height: 556px;
	text-align: center;
	position: relative;

	@media @mobile {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	@media @tablet {
		padding-top: round(percentage(@height/@width),2);
	}

	&__logo {
		.TopBarItem(773,@width,@height,auto,10,left,36);
	}

	&__container {
		padding: 0 15px;
	}

	&__call {
		.TopBarItem(857,@width,@height,auto,379,right,800);

		@media @mobile {
			max-width: 451px;
			position: relative;

			&:before {
				content: "";
				display: block;
				padding-top: 16.8514%;
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&__phone {
		.TopBarItem(968,@width,@height,auto,242,right,719);
		z-index: 1;
	}

	&__email {
		.TopBarItem(594,@width,@height,auto,156,right,1110);
		text-align: left;
		white-space: nowrap;
		line-height: 1;
		.Bjola;
		color: #000;
		font-size: 1.35rem;

		@media @mobile {
			display: block;
			font-size: 6vw;
			margin: 20px auto 0;
			text-align: center;
		}

		@media @tablet {
			width: 21.25%;
			bottom: 26.56%;
			right: 40.46%;
		}

		@media @tablet-only {
			font-size: 1.6vw;
		}

		&:hover {
			color: #000;
		}
	}

	&__cards {
		.TopBarItem(594,@width,@height,auto,59,right,1110);

		@media @mobile {
			display: block;
			margin: 20px auto;
			max-width: 350px;
			position: relative;

			&:before {
				content: "";
				display: block;
				padding-top: 9.1429%;
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&__bcn {
		.TopBarItem(214,@width,@height,auto,58,right,867);

		@media @mobile {
			height: 70px;
			width: 107px;
			margin: 0 10px;
		}
	}

	&__tipe {
		.TopBarItem(151,@width,@height,auto,52,right,702);

		@media @mobile {
			width: 77px;
			height: 80px;
			margin: 0 10px;
		}
	}

	&__weather {
		position: absolute;
		width: 18%;
		height: 84.04%;
		right: 0;
		bottom: 2%;
		z-index: 3;

		&:hover {
			.header__weather-sun-corona {
				transform: rotateZ(90deg) scale(1.2);
			}

			.header__weather-sun-body {
				transform: scale(1.2);
			}
		}
	}

	&__weather-bg {
		//edit fill color in svg
		position: absolute;
		bottom: 0;
		left: 0;
		width: 80%;
	}

	&__weather-sun-corona {
		position: absolute;
		top: 15%;
		right: -0.5%;
		width: 55%;
		transition: all 3s ease-in-out;
	}

	&__weather-sun-body {
		position: absolute;
		width: 43%;
		top: 23%;
		right: 5.5%;
		transition: all 3s ease-in-out;
		transform-origin: center center;
	}

	&__weather-text {
		position: absolute;
		bottom: 10%;
		left: 4%;
		width: 73%;
		transform-origin: center center;
	}

	#SocialMediaLinks {
		text-align: center;
		margin: 0 auto 30px;
		padding: 0 30px;

		@media @mobile {
			margin: 20px auto 0;
		}

		a {
			display: inline-block;
			margin:0 5px;

			@media @mobile {
				max-width: auto;

				img {
					width: 75px;
					height: 80px;
				}
			}

			@media @sm {
				max-width: 15%;
				margin: 2%;
			}
		}
	}

	&__category-list {
		margin: 20px auto;
		width: 90%;
		max-width: 400px;

		.form-control {
			min-height: 45px;
		}
	}
}

.toplinks {
	border-bottom-right-radius: 15px;
	position: relative;
	margin-top: 20px;
	margin-left: -20px;
	margin-right: -20px;

	&__inner {
		position: relative;
		padding: 0 10px;
		background-size: auto 100%;
		background-color: #363636;
		.Gradient(#464646,#242424);
		z-index: 4;
	}

	&:before, &:after {
		content: "";
		position: absolute;
		//edit fill color in sash.svg
		background-image: url("@{cloudUrl}sash.svg");
		background-size: 100% 100%;
		width: 6%;
		top: 25%;
		height: 100%;
		z-index: 1;
	}

	&:before {
		transform: translateX(-74%);
	}

	&:after {
		transform: translateX(74%) scaleX(-1);
		right: 0;
	}

	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		text-align: center;
		list-style: none;
		position: relative;
		.Transition3s();
		flex-grow: 1;

		&.open .dropdown-toggle:focus {
			color: #FFF;
		}
	}

	&__link {
		color: #FFF;
		background: none;
		.transition(all 0.3s);
		padding: 20px 10px;
		font-size: 1.8vw;
		white-space: nowrap;
		.LatoBold;
		display: block;

		@media @desktop {
			font-size: 1.5rem;
		}

		&:hover, &.active, &:focus {
			.Gradient(#d32d2d,#ea3232);
			color: #FFF;
			text-shadow: 2px 2px rgba(0,0,0,0.2);
		}
	}

	&__dropdown {
		background-color: rgba(0,0,0,0.8);
		text-align: center;
		padding: 0;
		.Gradient(#464646,#242424);
		left: auto;
		min-width: 100%;
	}

	&__dropdown-li {
		display: block;
		width: 100%;
		margin-left: 0;
		border-radius: 0;
	}

	&__dropdown-link {
		padding: 5px 10px !important;
		font-size: 1.8vw;
		color: #fff !important;
		.LatoBold;

		@media @desktop {
			font-size: 16px;
		}

		&:hover, &:focus, &.active {
			.Gradient(#d32d2d,#ea3232);
			color: #FFF !important;
		}
	}
}

.body-wrapper {
	background: #e5eaff;
	position: relative;

	&:before {
		.StickToEdges;
		background-repeat: repeat;
		background-image: url("@{cloudUrl}diagonal-line.svg");
		background-size: 10px;
		opacity: 0.05;
		transform: scaleX(-1);
	}
}

.banner {
	.BannerPanelSet(42.3077%);
	border: 2px solid #fff;
}

.book-online {
	position: relative;
	margin-left: -2%;
	margin-right: -2%;
	filter: drop-shadow(0 5px 10px rgba(0,0,0,0.2));
	z-index: 3;

	&__container {
		margin-top: -1%;
	}

	&:before, &:after {
		content: "";
		background-image: url("@{cloudUrl}sash-book-online.svg");
		background-size: 100% 100%;
		width: 6%;
		height: 85%;
		top: -22%;
		position: absolute;
		z-index: 2;
		transform: rotateX(180deg);
	}

	&:before {
		left: -4.6%;
	}

	&:after {
		right: -4.6%;
		transform: rotateY(180deg)rotateX(180deg);
	}

	&__inner {
		position: relative;
		padding-top: 8%;
		padding-left: 25%;
		.Gradient(#ef3336,#db2022);
		z-index: 3;
	}

	&__graphic {
		position: absolute;
		width: 19%;
		left: 3.5%;
		.TransformMiddleV;
		z-index: 3;
	}

	@width: 2874px;
	@height: 443px;

	#PostcodeSearchLeft select, #PostcodeSearchLeft input, .SearchDate, .SearchCategory {
		width: round(percentage(540/@width),2);
		.TransformMiddleV;
		height: 45%;
		border-radius: 15px;
		border: none;
		padding-left: 2%;
		box-shadow: 0 2px 3px rgba(255,255,255,0.3);
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input {
		left: round(percentage(704/@width),2);
	}

	.PostcodeMessage {
		position: absolute;
		left: 23%;
		right: 56%;
		top: 0;
		bottom: 0;
		margin: 0;
		padding: 10px;
		display: flex !important;
		justify-content: center;
		align-items: center;
	}

	.SearchDate {
		left: round(percentage(1284/@width),2);
	}

	.SearchCategory {
		left: round(percentage(1864/@width),2);
	}

	.SearchButton {
		position: absolute;
		.TransformMiddleV;
		width: round(percentage(316/@width),2);
		left: round(percentage(2500/@width),2);
		.Gradient(#67b73a,#439316);
		text-shadow: 0 1px #000;
		border-radius: 15px;
		.LatoBold;
		color: #fff;
		height: 45%;
		.FlexCenter;
		box-shadow: 0 2px 3px rgba(255,255,255,0.3);
		font-size: 2vw;

		&:before {
			content: "";
			display: block;
			position: absolute;
			border-radius: 15px;
			top: 0;
			left: 0;
			right: 0;
			bottom: 25%;
			background: rgba(255,255,255,0.1);
		}

		@media @desktop {
			font-size: 25px;
		}
	}
}

@media @mobile {

	.SearchPanel {
		position: relative;
		display: block;
		z-index: 3;
	}
}

.columns {
	display: flex;
	box-shadow: 0 5px 25px 0 rgba(0,0,0,0.2);
	border-radius: 15px;
	@columnsWidth: 1368px;
	@leftColWidth: 318px;
	@rightColWidth: 1050px;
	@padding: 54px;

	&__left {
		position: relative;
		background: #fff;
		width: round(percentage(@leftColWidth/@columnsWidth),2);
		z-index: 2;
		border-bottom-left-radius: 15px;
	}

	&__main {
		overflow: hidden;
		position: relative;
		background-color: #38c7ff;
		width: 100%;
		border-bottom-right-radius: 15px;

		@media @mobile {
			padding: 0;
			border-bottom-right-radius: 0;
		}

		@media @sm {
			width: round(percentage(@rightColWidth/@columnsWidth),2);
		}

		padding: 0 round(percentage(@padding/@columnsWidth),2);

		& > .columns__main-offset {
			&:first-child {
				background: #fff;

				@media @mobile {
				}
			}
		}
	}

	&__main-offset {
		@margin: -((percentage(@padding/(@rightColWidth - (@padding*2)))));
		margin: 0 ~'calc( @{margin} - 1px)';
		padding: 1px round(percentage(@padding/(@rightColWidth - (@padding*2))),8);

		@media @mobile {
			padding: 1px 5px;
			margin: 0;
		}
	}
}

.category-links {
	margin-bottom: 10px;
	position: relative;
	padding-top: 1px;
	margin-top: 40px;

	&:after {
		content: "";
		position: absolute;
		width: 8%;
		.HorizontalGradient(rgba(0,0,0,0.1), rgba(0,0,0,0));
		height: 100%;
		right: -8%;
		top: 0;
		filter: blur(3px);
	}

	&__link, &__child-link {
		position: relative;
		display: block;
		margin-top: 4%;
		margin-left: -3.5%;
		margin-right: -6%;
		padding: 10% 5% 5% 10%;
		text-align: left;
		line-height: 100%;
		.LatoBold;
		.TextShadow1();
		color: white;
		.Transition3s();
		background-size: 100% 100%;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 60%;
			transform: translateY(-50%);
			border-left: 8px solid #fff;
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
			opacity: 0;
			transition: opacity 0.3s;
		}

		&:nth-of-type(5n + 1) {
			background-image: url("@{cloudUrl}catlink-blue.png");

			+ div .category-links__child-link {
				background-image: url("@{cloudUrl}catlink-blue.png");
			}
		}

		&:nth-of-type(5n + 2) {
			background-image: url("@{cloudUrl}catlink-pink.png");

			+ div .category-links__child-link {
				background-image: url("@{cloudUrl}catlink-pink.png");
			}
		}

		&:nth-of-type(5n + 3) {
			background-image: url("@{cloudUrl}catlink-yellow.png");

			+ div .category-links__child-link {
				background-image: url("@{cloudUrl}catlink-yellow.png");
			}
		}

		&:nth-of-type(5n + 4) {
			background-image: url("@{cloudUrl}catlink-green.png");

			+ div .category-links__child-link {
				background-image: url("@{cloudUrl}catlink-green.png");
			}
		}

		&:nth-of-type(5n + 5) {
			background-image: url("@{cloudUrl}catlink-purple.png");

			+ div .category-links__child-link {
				background-image: url("@{cloudUrl}catlink-purple.png");
			}
		}


		@media @desktop {
			font-size: 20px;
		}

		&:hover, &:focus, &.active {
			text-shadow: 0 1px 1px #980000;
			color: #fff;

			&:before{
				opacity:1;
			}
		}

	}

	&__child-link {
		margin-left: 10px;
	}
}
/*#region Other Left Column Stuff*/
.left-sash {
	position: relative;
	z-index: 2;

	&__left, &__right {
		//edit fill colors in left-sash-side.svg
		background-image: url("@{cloudUrl}left-sash-side.svg");
		position: absolute;
		width: 9%;
		top: -18%;
		background-size: 100% 100%;
		height: 100%;
	}

	&__left {
		left: -9%;
	}

	&__right {
		right: -9%;
		transform: rotateY(180deg);
	}

	&__main {
		padding: 12% 5% 8%;
		//edit gradient stop colors in left-sash-main.svg
		background-image: url("@{cloudUrl}left-sash-main.svg");
		background-size: 100% 100%;
		text-align: center;
		transform: scale(1.1);

		img:first-child {
			margin-bottom: 5px;
			max-width: 80% !important;
		}
	}

	&__spacer {
		padding-bottom: 160%;
	}
}

.left-block {
	position: absolute;
	width: 100%;
	bottom: 40px;
	left: 0;

	&__logo {
		position: relative;
		z-index: 3;
	}

	&__spacer {
		padding-bottom: 80%;
	}
}

#SocialMediaLinks {
	text-align: center;

	a {
		display: inline-block;
		max-width: 40%;
		margin: 2%;
	}
}
/*#endregion Other Left Column Stuff*/
/*#region BootstrapCarouselFade*/
.carousel-fade .carousel-inner .item {
	opacity: 0;
	transition-property: opacity;
	transition-duration: 2s;
}

.carousel-fade .carousel-inner .active {
	opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
	left: auto;
	width: 100%;
	opacity: 0;
	z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
	opacity: 1;
}

.carousel-fade .carousel-control {
	z-index: 2;
}

@media all and (transform-3d), (-webkit-transform-3d) {
	.carousel-fade .carousel-inner > .item.next,
	.carousel-fade .carousel-inner > .item.active.right {
		opacity: 0;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.carousel-fade .carousel-inner > .item.prev,
	.carousel-fade .carousel-inner > .item.active.left {
		opacity: 0;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.carousel-fade .carousel-inner > .item.next.left,
	.carousel-fade .carousel-inner > .item.prev.right,
	.carousel-fade .carousel-inner > .item.active {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
/*#endregion BootstrapCarouselFade*/
.newsletter {
	@width: 1900px;
	@height: 272px;
	padding-top: 14.3%;
	.Gradient(#60cf5d,#319f31);
	border-radius: 10px;
	position: relative;
	text-align: center;

	@media @mobile {
		padding: 20px;
	}

	&__text {
		display: block;
		margin: 0 auto;

		@media @tablet {
			position: absolute;
			width: round(percentage(809/@width),2);
			left: round(percentage(50/@width),2);
			.TransformMiddleV;
			margin-top: 0.5%;
		}
	}

	input {
		border-radius: 10px;
		padding-left: 2%;
		border: none;
		box-shadow: 0 2px #63df60;
		min-height: 40px;
		display: block;
		width: 100%;
		max-width: 410px;

		@media @mobile {
			text-align: center;
			margin: 20px auto 0;
		}

		@media @tablet {
			position: absolute;
			width: round(percentage(560/@width),2);
			left: round(percentage(938/@width),2);
			.TransformMiddleV;
			height: 45%;
		}
	}

	.NewsletterSubmit {
		.Gradient(#f0392c,#bf1307);
		text-shadow: 0 1px #000;
		border-radius: 10px;
		.LatoBold;
		color: #fff;
		.FlexCenter;
		box-shadow: 0 2px #59fc56;
		font-size: 5vw;

		@media @mobile {
			margin-top: 20px;
			padding: 5px 20px;
			display: inline-block;
		}

		@media @tablet {
			font-size: 25px;
			height: 45%;
			position: absolute;
			width: round(percentage(316/@width),2);
			left: round(percentage(1544/@width),2);
			.TransformMiddleV;
		}
	}
}

.testimonials {
	//heights set from theme.js
	background: transparent;
	text-align: center;

	.section-title {
		position: relative;
	}

	&__panels {
		background-color: #003472;
		position: relative;
		padding-bottom: 50px;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-image: url("@{cloudUrl}jagged-bg.png");
			background-size: 16px 10px;
			background-repeat: repeat;
			opacity: 0.2;
		}
	}

	&__intro {
		position: relative;
		padding-top: 180px;
		margin-top: -80px;

		&-text {
			z-index: 1;
			margin-top: 20px;
		}

		&-city-front {
			position: absolute;
			bottom: -1px;
			left: 0;
			right: 0;
			height: 60%;
			//background-image: url(city-front.png);
			background-position: center bottom;
			//background-size: 80% auto;
			background-size: auto 200%;
			background-image: url("@{cloudUrl}city-front.svg");
			background-size: auto 100%;
		}

		&-city-back {
			.StickToEdges;
			background-repeat: repeat-x;
			background-image: url("@{cloudUrl}city-back.png");
			background-position: center top;
			// background-size: 80% auto;
			background-size: auto 136%;
			background-image: url("@{cloudUrl}city-back.svg");
			background-size: auto 100%;
		}
	}

	&__container {
		margin: 10px;
		background: #fff;
		padding: 25px 20px;
		border-radius: 3px;
	}

	&__link {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		height: 100%;
	}

	&__text {
		color: #808080;
		margin-bottom: 20px;
		text-align: left;
	}

	&__author {
		color: #2babbf;
		background-size: 100% 100%;
		position: relative;
		text-align: left;

		&:before {
			content: "";
			position: absolute;
			width: 10px;
			height: 10px;
			top: -10px;
			right: 0;
			border-right: 10px solid transparent;
		}
	}

	.item, .carousel-inner {
		overflow: visible !important;
	}
}
/*#region Castle Panels*/
.castlePanel {
	width: 100%;
	border: none;
	padding: 10px 10px 20px;
	background-color: white;
	border-radius: 10px;
	margin: 0 0 30px;
	text-align: center;
	transition: all 0.3s;
	background: linear-gradient(to bottom,#d24818 0,#f0ad39 69%,#dd4a19 100%);
	position: relative;

	&:before {
		background-image: url("@{cloudUrl}diagonal-line.svg");
		background-size: 10px;
		height: 100%;
		width: 100%;
		content: ' ';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.05;
	}

	&:hover {
		.castleCheckBook {
			color: yellow;
		}

		box-shadow: 5px 5px 6px 0 rgba(0,0,0,.075);
	}

	.castleTitlePanel {
		margin-top: 10px;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			.Bjola;
			font-weight: normal;
			text-shadow: 0 1px 1px rgba(0,0,0,.75);
			color: #fff;
			font-size: 1.4rem;
			position: relative;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		color: #fff;
		.Bjola;
		text-shadow: 0 1px 1px rgba(0,0,0,.75);
		font-size: 1.6rem;
		position: relative;
		padding: 0;

		span.oldprice, span.newprice {
			font-size: 1.1rem;
		}
	}

	.castleCheckBook {
		position: relative;
		border-radius: 20px;
		color: #fff;
		font-size: 18px;
		background-color: #f0382c;
		background: linear-gradient(to bottom,#f0382c,#be1206);
		text-shadow: 0 1px 1px rgba(0,0,0,.75);
		box-shadow: 0 1px 1px 1px rgba(255,255,255,.2);
		height: auto;
		padding: 10px 20px;
		display: inline-block;
		.LatoBold;
		margin-top: 10px;
	}
}

#PageListings {
	& > div {
		@media (max-width: 400px) {
			width: 100%;
		}
	}

	.castlePanel { // add default style to .castlePanel not in #PageListings for search results / sme other pages I think
	}

	& > div {
		&:nth-child(6n + 1) .castlePanel {
			background: linear-gradient(to bottom,#d24818 0,#f0ad39 69%,#dd4a19 100%);
		}

		&:nth-child(6n + 2) .castlePanel {
			background: linear-gradient(to bottom,#2da12b 0,#68d664 69%,#2c9a2a 100%);
		}

		&:nth-child(6n + 3) .castlePanel {
			background: linear-gradient(to bottom,#8c378f 0,#c778c9 69%,#86358a 100%);
		}

		&:nth-child(6n + 4) .castlePanel {
			background: linear-gradient(to bottom,#eb1447 0,#f62d9c 69%,#e11445 100%);
		}

		&:nth-child(6n + 5) .castlePanel {
			background: linear-gradient(to bottom,#ee9523 0,#f6d052 69%,#e28d22 100%);
		}

		&:nth-child(6n + 6) .castlePanel {
			background: linear-gradient(to bottom,#199db8 0,#39d4df 69%,#1895b0 100%);
		}
	}
}
/*#endregion Castle Panels*/
.clouds {
	.BackgroundImg("@{cloudUrl}bottom-clouds", png);
	padding-top: 10.76%;
	background-size: 100% 100%;
}

.page-listings {
	z-index: 1;

	&__title.animated {
		.char {
			animation: slide-in 1s cubic-bezier(.5, 0, .5, 1) both;
			animation-delay: calc(60ms * var(--char-index));
		}
	}
}




.DetailsTitle h1, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: auto;
		display: inline-block;
		margin: 20px 5px 10px;
		float: none;
		width: 195px;
	}
}

.footer {
	&__wrapper {
		background-color: #000;
		color: #fff;
		padding: 25px 10px;
		width: 100%;
		z-index: 1;
		position: relative;

		@media @mobile {
			text-align: center;
		}
	}

	&__links {
		text-align: center;

		@media @mobile {
			text-align: center;
			margin-top: 15px;
		}

		a {
			color: white;
			margin-right: 10px;
		}
	}

	&__BCN {
		display: block;
		max-width: 180px;

		@media @mobile {
			text-align: center;
			display: inline-block;
		}

		@media @sm {
			float: right;
		}

		img {
			margin: 0 auto;
		}
	}

	&__flex-row-sm {
		@media @sm {
			display: flex;
			align-items: center;

			p {
				margin-bottom: 0;
			}
		}
	}
}

#BouncyCastleHireLink {
	color: white;
	font-weight: 700;
}

.top-wrapper {
	padding-top: 1px;
}

.body-wrapper {
	min-height: 150vh;
}


.outer-wrapper {
	overflow: visible;
}

.outer-wrapper--alt .top-wrapper__starburst {
	top: 0;
	transform: none;
	padding-top: 0;
	background: none;
	overflow: hidden;

	&:before {
		content: "";
		display: block;
		top: 0;
		left: 0;
		padding-top: 100%;
		transform: scale(2);
		background-image: url("@{cloudUrl}starburst.svg");
	}
}




/*#region Utiliy Classes*/
.white {
	color: #fff;
}

.text-shadow-big {
	text-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}
/*#endregion Utiliy Classes*/

.lower-content {
	background: #fff;
	border-radius: 10px;
	padding: 15px 20px;
	position:relative;
	z-index:1;

	h2 {
		color: #e77c29;
		.section-title;
	}
}

/*#region Animations*/
.wow {
	visibility: hidden;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

.fadeIn2 {
	animation-name: fadeIn2;
	animation-fill-mode: both;
	animation-timing-function: ease-out;
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0,100%,0)
	}

	to {
		opacity: 1;
		transform: none
	}
}

.fadeInUp {
	animation-name: fadeInUp
}


@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translate3d(0,-100%,0)
	}

	to {
		opacity: 1;
		transform: none
	}
}

.fadeInDown {
	animation-name: fadeInDown
}


.animated.bounceIn {
	animation-duration: .75s
}

@keyframes bounceIn {
	0%,20%,40%,60%,80%,to {
		animation-timing-function: cubic-bezier(.215,.61,.355,1)
	}

	0% {
		opacity: 0;
		transform: scale3d(.3,.3,.3)
	}

	20% {
		transform: scale3d(1.1,1.1,1.1)
	}

	40% {
		transform: scale3d(.9,.9,.9)
	}

	60% {
		opacity: 1;
		transform: scale3d(1.03,1.03,1.03)
	}

	80% {
		transform: scale3d(.97,.97,.97)
	}

	to {
		opacity: 1;
		transform: scaleX(1)
	}
}

.bounceIn {
	animation-name: bounceIn
}

@keyframes fadeIn2 {
	to {
		opacity: 0.2;
	}
}

@keyframes slide-in {
	from {
		transform: translateY(-1em) rotate(-.5turn) scale(0.5);
		opacity: 0;
	}
}
/*#endregion Animations*/
