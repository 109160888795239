/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region Fonts*/
.Lato,
.BodyFont {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.LatoBold {
  font-weight: 700;
  font-family: 'Lato', sans-serif;
}
@font-face {
  font-family: 'Bjola';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.svg#bjola') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.Bjola {
  font-family: 'Bjola';
}
/*#endregion Fonts*/
html {
  font-size: 16px;
}
body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  background: #fff;
}
form {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
#BodyContent {
  padding-top: 1px;
}
.textContent {
  padding: 0;
}
@media (max-width: 767px) {
  .textContent {
    padding: 0 10px;
  }
}
.textContent h1 {
  color: #e77c29;
  font-family: 'Bjola';
  font-size: 5vw;
  text-align: center;
  visibility: hidden;
}
@media (max-width: 767px) {
  .textContent h1 {
    font-size: 2.5rem;
  }
}
@media (min-width: 1330px) {
  .textContent h1 {
    font-size: 54px;
  }
}
@media (max-width: 767px) {
  .textContent h1 {
    margin-top: 0;
  }
}
.splitter.animated .char {
  -webkit-animation: slide-in 1s cubic-bezier(0.5, 0, 0.5, 1) both;
          animation: slide-in 1s cubic-bezier(0.5, 0, 0.5, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
          animation-delay: calc(60ms * var(--char-index));
}
.section-title {
  font-family: 'Bjola';
  font-size: 5vw;
  text-align: center;
}
@media (max-width: 767px) {
  .section-title {
    font-size: 2.5rem;
  }
}
@media (min-width: 1330px) {
  .section-title {
    font-size: 54px;
  }
}
.nicefont {
  font-weight: 700;
  font-family: 'Lato', sans-serif;
}
.outer-wrapper {
  overflow: hidden;
  max-width: 100vw;
  position: relative;
}
.outer-wrapper--alt .top-wrapper__city-back {
  -webkit-transform: translateY(40%);
          transform: translateY(40%);
}
.outer-wrapper--alt .top-wrapper__city-front {
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}
.outer-wrapper--alt .top-wrapper__starburst {
  -webkit-transform: scale(2);
          transform: scale(2);
  top: -137%;
  left: 0;
}
.outer-wrapper--alt .header__email {
  -webkit-filter: drop-shadow(0 0 5px #fff);
          filter: drop-shadow(0 0 5px #fff);
}
.outer-wrapper--alt .page-listings > h2 {
  display: none;
}
.outer-wrapper--alt .category-links {
  margin-top: 10px;
}
.outer-wrapper--homepage {
  /*.top-wrapper__city-blue {
			position: absolute;
			display: block;
			bottom: 0;
			left: 0;
			padding-bottom: 40%;
			background: #003670;
			width: 100%;
		}*/
}
@media (max-width: 767px) {
  .outer-wrapper--homepage .top-wrapper__city-back {
    bottom: 15%;
    visibility: visible !important;
  }
  .outer-wrapper--homepage .top-wrapper__city-front {
    bottom: 13%;
    visibility: visible !important;
  }
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    margin: 0 auto 0;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto 0;
  }
}
@media (min-width: 1330px) {
  .container {
    width: 1300px;
    margin: 0 auto 0;
  }
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
  padding-top: 53px;
}
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #ea1d25;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  min-height: 5px;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: block !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
  height: 51px;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0% 0%;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
  position: relative;
  background-color: #0bc3ff;
}
.top-wrapper__gradient {
  position: absolute;
  width: 100vw;
  max-width: 2130px;
  height: 100%;
  top: 0;
  background-color: #11bbff;
  background: -webkit-gradient(linear, left top, left bottom, from(#11bbff), to(#27d7ff));
  background: linear-gradient(to bottom, #11bbff, #27d7ff);
}
.top-wrapper__curve {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 10%;
  -webkit-mask: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/curve-bottom-mask.svg") center bottom / contain;
          mask: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/curve-bottom-mask.svg") center bottom / contain;
  -webkit-mask-size: 101% 100%;
          mask-size: 101% 100%;
  background: #e5eaff;
}
.top-wrapper__curve:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/diagonal-line.svg");
  background-size: 10px;
  opacity: 0.05;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  background-position: 0 10.7%;
}
.top-wrapper__city-back {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 27%;
  background-position: top left;
  background-repeat: repeat-x;
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/020/city-back.svg");
  background-size: auto 100%;
}
@media (max-width: 767px) {
  .top-wrapper__city-back {
    background-position: bottom left;
  }
}
.top-wrapper__city-front {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 20%;
  background-repeat: repeat-x;
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/020/city-front.svg");
  background-size: auto 100%;
}
@media (max-width: 767px) {
  .top-wrapper__city-front {
    background-position: bottom left;
  }
}
.top-wrapper__city-blue {
  display: none;
}
.top-wrapper__starburst {
  position: absolute;
  top: -50%;
  left: -20%;
  width: 100%;
  padding-top: 100%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/020/starburst.svg");
  opacity: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-transform: translate(-40%, -40%);
          transform: translate(-40%, -40%);
}
@media (max-width: 767px) {
  .top-wrapper__starburst {
    -webkit-transform: translateY(-23%);
            transform: translateY(-23%);
    top: -11% !important;
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}
.header {
  text-align: center;
  position: relative;
}
@media (max-width: 767px) {
  .header {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .header {
    padding-top: 20.17%;
  }
}
@media (max-width: 767px) {
  .header__logo {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    width: 28.04%;
    bottom: 1.8%;
    left: 1.31%;
  }
}
.header__container {
  padding: 0 15px;
}
@media (max-width: 767px) {
  .header__call {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__call {
    position: absolute;
    width: 31.08%;
    bottom: 68.17%;
    right: 29.02%;
  }
}
@media (max-width: 767px) {
  .header__call {
    max-width: 451px;
    position: relative;
  }
  .header__call:before {
    content: "";
    display: block;
    padding-top: 16.8514%;
  }
  .header__call img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.header__phone {
  z-index: 1;
}
@media (max-width: 767px) {
  .header__phone {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__phone {
    position: absolute;
    width: 35.11%;
    bottom: 43.53%;
    right: 26.08%;
  }
}
.header__email {
  text-align: left;
  white-space: nowrap;
  line-height: 1;
  font-family: 'Bjola';
  color: #000;
  font-size: 1.35rem;
}
@media (max-width: 767px) {
  .header__email {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__email {
    position: absolute;
    width: 21.55%;
    bottom: 28.06%;
    right: 40.26%;
  }
}
@media (max-width: 767px) {
  .header__email {
    display: block;
    font-size: 6vw;
    margin: 20px auto 0;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .header__email {
    width: 21.25%;
    bottom: 26.56%;
    right: 40.46%;
  }
}
@media (min-width: 768px) and (max-width: 1329px) {
  .header__email {
    font-size: 1.6vw;
  }
}
.header__email:hover {
  color: #000;
}
@media (max-width: 767px) {
  .header__cards {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__cards {
    position: absolute;
    width: 21.55%;
    bottom: 10.61%;
    right: 40.26%;
  }
}
@media (max-width: 767px) {
  .header__cards {
    display: block;
    margin: 20px auto;
    max-width: 350px;
    position: relative;
  }
  .header__cards:before {
    content: "";
    display: block;
    padding-top: 9.1429%;
  }
  .header__cards img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (max-width: 767px) {
  .header__bcn {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__bcn {
    position: absolute;
    width: 7.76%;
    bottom: 10.43%;
    right: 31.45%;
  }
}
@media (max-width: 767px) {
  .header__bcn {
    height: 70px;
    width: 107px;
    margin: 0 10px;
  }
}
@media (max-width: 767px) {
  .header__tipe {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__tipe {
    position: absolute;
    width: 5.48%;
    bottom: 9.35%;
    right: 25.46%;
  }
}
@media (max-width: 767px) {
  .header__tipe {
    width: 77px;
    height: 80px;
    margin: 0 10px;
  }
}
.header__weather {
  position: absolute;
  width: 18%;
  height: 84.04%;
  right: 0;
  bottom: 2%;
  z-index: 3;
}
.header__weather:hover .header__weather-sun-corona {
  -webkit-transform: rotateZ(90deg) scale(1.2);
          transform: rotateZ(90deg) scale(1.2);
}
.header__weather:hover .header__weather-sun-body {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.header__weather-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
}
.header__weather-sun-corona {
  position: absolute;
  top: 15%;
  right: -0.5%;
  width: 55%;
  -webkit-transition: all 3s ease-in-out;
  transition: all 3s ease-in-out;
}
.header__weather-sun-body {
  position: absolute;
  width: 43%;
  top: 23%;
  right: 5.5%;
  -webkit-transition: all 3s ease-in-out;
  transition: all 3s ease-in-out;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
.header__weather-text {
  position: absolute;
  bottom: 10%;
  left: 4%;
  width: 73%;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
.header #SocialMediaLinks {
  text-align: center;
  margin: 0 auto 30px;
  padding: 0 30px;
}
@media (max-width: 767px) {
  .header #SocialMediaLinks {
    margin: 20px auto 0;
  }
}
.header #SocialMediaLinks a {
  display: inline-block;
  margin: 0 5px;
}
@media (max-width: 767px) {
  .header #SocialMediaLinks a {
    max-width: auto;
  }
  .header #SocialMediaLinks a img {
    width: 75px;
    height: 80px;
  }
}
@media (min-width: 768px) {
  .header #SocialMediaLinks a {
    max-width: 15%;
    margin: 2%;
  }
}
.header__category-list {
  margin: 20px auto;
  width: 90%;
  max-width: 400px;
}
.header__category-list .form-control {
  min-height: 45px;
}
.toplinks {
  border-bottom-right-radius: 15px;
  position: relative;
  margin-top: 20px;
  margin-left: -20px;
  margin-right: -20px;
}
.toplinks__inner {
  position: relative;
  padding: 0 10px;
  background-size: auto 100%;
  background-color: #363636;
  background-color: #464646;
  background: -webkit-gradient(linear, left top, left bottom, from(#464646), to(#242424));
  background: linear-gradient(to bottom, #464646, #242424);
  z-index: 4;
}
.toplinks:before,
.toplinks:after {
  content: "";
  position: absolute;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/sash.svg");
  background-size: 100% 100%;
  width: 6%;
  top: 25%;
  height: 100%;
  z-index: 1;
}
.toplinks:before {
  -webkit-transform: translateX(-74%);
          transform: translateX(-74%);
}
.toplinks:after {
  -webkit-transform: translateX(74%) scaleX(-1);
          transform: translateX(74%) scaleX(-1);
  right: 0;
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  text-align: center;
  list-style: none;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.toplinks__li.open .dropdown-toggle:focus {
  color: #FFF;
}
.toplinks__link {
  color: #FFF;
  background: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 20px 10px;
  font-size: 1.8vw;
  white-space: nowrap;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  display: block;
}
@media (min-width: 1330px) {
  .toplinks__link {
    font-size: 1.5rem;
  }
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  background-color: #d32d2d;
  background: -webkit-gradient(linear, left top, left bottom, from(#d32d2d), to(#ea3232));
  background: linear-gradient(to bottom, #d32d2d, #ea3232);
  color: #FFF;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
.toplinks__dropdown {
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 0;
  background-color: #464646;
  background: -webkit-gradient(linear, left top, left bottom, from(#464646), to(#242424));
  background: linear-gradient(to bottom, #464646, #242424);
  left: auto;
  min-width: 100%;
}
.toplinks__dropdown-li {
  display: block;
  width: 100%;
  margin-left: 0;
  border-radius: 0;
}
.toplinks__dropdown-link {
  padding: 5px 10px !important;
  font-size: 1.8vw;
  color: #fff !important;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
}
@media (min-width: 1330px) {
  .toplinks__dropdown-link {
    font-size: 16px;
  }
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: #d32d2d;
  background: -webkit-gradient(linear, left top, left bottom, from(#d32d2d), to(#ea3232));
  background: linear-gradient(to bottom, #d32d2d, #ea3232);
  color: #FFF !important;
}
.body-wrapper {
  background: #e5eaff;
  position: relative;
}
.body-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/diagonal-line.svg");
  background-size: 10px;
  opacity: 0.05;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.banner {
  border: 2px solid #fff;
}
.banner #CarouselContainer {
  padding-top: 42.3077%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.book-online {
  position: relative;
  margin-left: -2%;
  margin-right: -2%;
  -webkit-filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.2));
  z-index: 3;
}
.book-online__container {
  margin-top: -1%;
}
.book-online:before,
.book-online:after {
  content: "";
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/sash-book-online.svg");
  background-size: 100% 100%;
  width: 6%;
  height: 85%;
  top: -22%;
  position: absolute;
  z-index: 2;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}
.book-online:before {
  left: -4.6%;
}
.book-online:after {
  right: -4.6%;
  -webkit-transform: rotateY(180deg) rotateX(180deg);
          transform: rotateY(180deg) rotateX(180deg);
}
.book-online__inner {
  position: relative;
  padding-top: 8%;
  padding-left: 25%;
  background-color: #ef3336;
  background: -webkit-gradient(linear, left top, left bottom, from(#ef3336), to(#db2022));
  background: linear-gradient(to bottom, #ef3336, #db2022);
  z-index: 3;
}
.book-online__graphic {
  width: 19%;
  left: 3.5%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 3;
}
.book-online #PostcodeSearchLeft select,
.book-online #PostcodeSearchLeft input,
.book-online .SearchDate,
.book-online .SearchCategory {
  width: 18.79%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 45%;
  border-radius: 15px;
  border: none;
  padding-left: 2%;
  -webkit-box-shadow: 0 2px 3px rgba(255, 255, 255, 0.3);
          box-shadow: 0 2px 3px rgba(255, 255, 255, 0.3);
}
.book-online #PostcodeSearchLeft select,
.book-online #PostcodeSearchLeft input {
  left: 24.5%;
}
.book-online .PostcodeMessage {
  position: absolute;
  left: 23%;
  right: 56%;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 10px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.book-online .SearchDate {
  left: 44.68%;
}
.book-online .SearchCategory {
  left: 64.86%;
}
.book-online .SearchButton {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 11%;
  left: 86.99%;
  background-color: #67b73a;
  background: -webkit-gradient(linear, left top, left bottom, from(#67b73a), to(#439316));
  background: linear-gradient(to bottom, #67b73a, #439316);
  text-shadow: 0 1px #000;
  border-radius: 15px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  color: #fff;
  height: 45%;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0 2px 3px rgba(255, 255, 255, 0.3);
          box-shadow: 0 2px 3px rgba(255, 255, 255, 0.3);
  font-size: 2vw;
}
.book-online .SearchButton:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 15px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 25%;
  background: rgba(255, 255, 255, 0.1);
}
@media (min-width: 1330px) {
  .book-online .SearchButton {
    font-size: 25px;
  }
}
@media (max-width: 767px) {
  .SearchPanel {
    position: relative;
    display: block;
    z-index: 3;
  }
}
.columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}
.columns__left {
  position: relative;
  background: #fff;
  width: 23.25%;
  z-index: 2;
  border-bottom-left-radius: 15px;
}
.columns__main {
  overflow: hidden;
  position: relative;
  background-color: #38c7ff;
  width: 100%;
  border-bottom-right-radius: 15px;
  padding: 0 3.95%;
}
@media (max-width: 767px) {
  .columns__main {
    padding: 0;
    border-bottom-right-radius: 0;
  }
}
@media (min-width: 768px) {
  .columns__main {
    width: 76.75%;
  }
}
.columns__main > .columns__main-offset:first-child {
  background: #fff;
}
.columns__main-offset {
  margin: 0 calc( -5.7324840764331215% - 1px);
  padding: 1px 5.73248408%;
}
@media (max-width: 767px) {
  .columns__main-offset {
    padding: 1px 5px;
    margin: 0;
  }
}
.category-links {
  margin-bottom: 10px;
  position: relative;
  padding-top: 1px;
  margin-top: 40px;
}
.category-links:after {
  content: "";
  position: absolute;
  width: 8%;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  height: 100%;
  right: -8%;
  top: 0;
  -webkit-filter: blur(3px);
          filter: blur(3px);
}
.category-links__link,
.category-links__child-link {
  position: relative;
  display: block;
  margin-top: 4%;
  margin-left: -3.5%;
  margin-right: -6%;
  padding: 10% 5% 5% 10%;
  text-align: left;
  line-height: 100%;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  text-shadow: 1px 1px 2px #000;
  color: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-size: 100% 100%;
}
.category-links__link:before,
.category-links__child-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 60%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-left: 8px solid #fff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.category-links__link:nth-of-type(5n + 1),
.category-links__child-link:nth-of-type(5n + 1) {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/catlink-blue.png");
}
.category-links__link:nth-of-type(5n + 1) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 1) + div .category-links__child-link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/catlink-blue.png");
}
.category-links__link:nth-of-type(5n + 2),
.category-links__child-link:nth-of-type(5n + 2) {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/catlink-pink.png");
}
.category-links__link:nth-of-type(5n + 2) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 2) + div .category-links__child-link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/catlink-pink.png");
}
.category-links__link:nth-of-type(5n + 3),
.category-links__child-link:nth-of-type(5n + 3) {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/catlink-yellow.png");
}
.category-links__link:nth-of-type(5n + 3) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 3) + div .category-links__child-link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/catlink-yellow.png");
}
.category-links__link:nth-of-type(5n + 4),
.category-links__child-link:nth-of-type(5n + 4) {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/catlink-green.png");
}
.category-links__link:nth-of-type(5n + 4) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 4) + div .category-links__child-link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/catlink-green.png");
}
.category-links__link:nth-of-type(5n + 5),
.category-links__child-link:nth-of-type(5n + 5) {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/catlink-purple.png");
}
.category-links__link:nth-of-type(5n + 5) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 5) + div .category-links__child-link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/catlink-purple.png");
}
@media (min-width: 1330px) {
  .category-links__link,
  .category-links__child-link {
    font-size: 20px;
  }
}
.category-links__link:hover,
.category-links__child-link:hover,
.category-links__link:focus,
.category-links__child-link:focus,
.category-links__link.active,
.category-links__child-link.active {
  text-shadow: 0 1px 1px #980000;
  color: #fff;
}
.category-links__link:hover:before,
.category-links__child-link:hover:before,
.category-links__link:focus:before,
.category-links__child-link:focus:before,
.category-links__link.active:before,
.category-links__child-link.active:before {
  opacity: 1;
}
.category-links__child-link {
  margin-left: 10px;
}
/*#region Other Left Column Stuff*/
.left-sash {
  position: relative;
  z-index: 2;
}
.left-sash__left,
.left-sash__right {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/left-sash-side.svg");
  position: absolute;
  width: 9%;
  top: -18%;
  background-size: 100% 100%;
  height: 100%;
}
.left-sash__left {
  left: -9%;
}
.left-sash__right {
  right: -9%;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.left-sash__main {
  padding: 12% 5% 8%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/left-sash-main.svg");
  background-size: 100% 100%;
  text-align: center;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.left-sash__main img:first-child {
  margin-bottom: 5px;
  max-width: 80% !important;
}
.left-sash__spacer {
  padding-bottom: 160%;
}
.left-block {
  position: absolute;
  width: 100%;
  bottom: 40px;
  left: 0;
}
.left-block__logo {
  position: relative;
  z-index: 3;
}
.left-block__spacer {
  padding-bottom: 80%;
}
#SocialMediaLinks {
  text-align: center;
}
#SocialMediaLinks a {
  display: inline-block;
  max-width: 40%;
  margin: 2%;
}
/*#endregion Other Left Column Stuff*/
/*#region BootstrapCarouselFade*/
.carousel-fade .carousel-inner .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: auto;
  width: 100%;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/*#endregion BootstrapCarouselFade*/
.newsletter {
  padding-top: 14.3%;
  background-color: #60cf5d;
  background: -webkit-gradient(linear, left top, left bottom, from(#60cf5d), to(#319f31));
  background: linear-gradient(to bottom, #60cf5d, #319f31);
  border-radius: 10px;
  position: relative;
  text-align: center;
}
@media (max-width: 767px) {
  .newsletter {
    padding: 20px;
  }
}
.newsletter__text {
  display: block;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .newsletter__text {
    width: 42.58%;
    left: 2.63%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: 0.5%;
  }
}
.newsletter input {
  border-radius: 10px;
  padding-left: 2%;
  border: none;
  -webkit-box-shadow: 0 2px #63df60;
          box-shadow: 0 2px #63df60;
  min-height: 40px;
  display: block;
  width: 100%;
  max-width: 410px;
}
@media (max-width: 767px) {
  .newsletter input {
    text-align: center;
    margin: 20px auto 0;
  }
}
@media (min-width: 768px) {
  .newsletter input {
    width: 29.47%;
    left: 49.37%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 45%;
  }
}
.newsletter .NewsletterSubmit {
  background-color: #f0392c;
  background: -webkit-gradient(linear, left top, left bottom, from(#f0392c), to(#bf1307));
  background: linear-gradient(to bottom, #f0392c, #bf1307);
  text-shadow: 0 1px #000;
  border-radius: 10px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  color: #fff;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0 2px #59fc56;
          box-shadow: 0 2px #59fc56;
  font-size: 5vw;
}
@media (max-width: 767px) {
  .newsletter .NewsletterSubmit {
    margin-top: 20px;
    padding: 5px 20px;
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .newsletter .NewsletterSubmit {
    font-size: 25px;
    height: 45%;
    width: 16.63%;
    left: 81.26%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.testimonials {
  background: transparent;
  text-align: center;
}
.testimonials .section-title {
  position: relative;
}
.testimonials__panels {
  background-color: #003472;
  position: relative;
  padding-bottom: 50px;
}
.testimonials__panels:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/jagged-bg.png");
  background-size: 16px 10px;
  background-repeat: repeat;
  opacity: 0.2;
}
.testimonials__intro {
  position: relative;
  padding-top: 180px;
  margin-top: -80px;
}
.testimonials__intro-text {
  z-index: 1;
  margin-top: 20px;
}
.testimonials__intro-city-front {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 60%;
  background-position: center bottom;
  background-size: auto 200%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/city-front.svg");
  background-size: auto 100%;
}
.testimonials__intro-city-back {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat-x;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/city-back.png");
  background-position: center top;
  background-size: auto 136%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/city-back.svg");
  background-size: auto 100%;
}
.testimonials__container {
  margin: 10px;
  background: #fff;
  padding: 25px 20px;
  border-radius: 3px;
}
.testimonials__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.testimonials__text {
  color: #808080;
  margin-bottom: 20px;
  text-align: left;
}
.testimonials__author {
  color: #2babbf;
  background-size: 100% 100%;
  position: relative;
  text-align: left;
}
.testimonials__author:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: -10px;
  right: 0;
  border-right: 10px solid transparent;
}
.testimonials .item,
.testimonials .carousel-inner {
  overflow: visible !important;
}
/*#region Castle Panels*/
.castlePanel {
  width: 100%;
  border: none;
  padding: 10px 10px 20px;
  background-color: white;
  border-radius: 10px;
  margin: 0 0 30px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #d24818), color-stop(69%, #f0ad39), to(#dd4a19));
  background: linear-gradient(to bottom, #d24818 0, #f0ad39 69%, #dd4a19 100%);
  position: relative;
}
.castlePanel:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/diagonal-line.svg");
  background-size: 10px;
  height: 100%;
  width: 100%;
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.05;
}
.castlePanel:hover {
  -webkit-box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
          box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
}
.castlePanel:hover .castleCheckBook {
  color: yellow;
}
.castlePanel .castleTitlePanel {
  margin-top: 10px;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  font-family: 'Bjola';
  font-weight: normal;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
  color: #fff;
  font-size: 1.4rem;
  position: relative;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  color: #fff;
  font-family: 'Bjola';
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
  font-size: 1.6rem;
  position: relative;
  padding: 0;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice,
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 1.1rem;
}
.castlePanel .castleCheckBook {
  position: relative;
  border-radius: 20px;
  color: #fff;
  font-size: 18px;
  background-color: #f0382c;
  background: -webkit-gradient(linear, left top, left bottom, from(#f0382c), to(#be1206));
  background: linear-gradient(to bottom, #f0382c, #be1206);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0 1px 1px 1px rgba(255, 255, 255, 0.2);
          box-shadow: 0 1px 1px 1px rgba(255, 255, 255, 0.2);
  height: auto;
  padding: 10px 20px;
  display: inline-block;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  margin-top: 10px;
}
@media (max-width: 400px) {
  #PageListings > div {
    width: 100%;
  }
}
#PageListings > div:nth-child(6n + 1) .castlePanel {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #d24818), color-stop(69%, #f0ad39), to(#dd4a19));
  background: linear-gradient(to bottom, #d24818 0, #f0ad39 69%, #dd4a19 100%);
}
#PageListings > div:nth-child(6n + 2) .castlePanel {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #2da12b), color-stop(69%, #68d664), to(#2c9a2a));
  background: linear-gradient(to bottom, #2da12b 0, #68d664 69%, #2c9a2a 100%);
}
#PageListings > div:nth-child(6n + 3) .castlePanel {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #8c378f), color-stop(69%, #c778c9), to(#86358a));
  background: linear-gradient(to bottom, #8c378f 0, #c778c9 69%, #86358a 100%);
}
#PageListings > div:nth-child(6n + 4) .castlePanel {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #eb1447), color-stop(69%, #f62d9c), to(#e11445));
  background: linear-gradient(to bottom, #eb1447 0, #f62d9c 69%, #e11445 100%);
}
#PageListings > div:nth-child(6n + 5) .castlePanel {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ee9523), color-stop(69%, #f6d052), to(#e28d22));
  background: linear-gradient(to bottom, #ee9523 0, #f6d052 69%, #e28d22 100%);
}
#PageListings > div:nth-child(6n + 6) .castlePanel {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #199db8), color-stop(69%, #39d4df), to(#1895b0));
  background: linear-gradient(to bottom, #199db8 0, #39d4df 69%, #1895b0 100%);
}
/*#endregion Castle Panels*/
.clouds {
  background-image: url(https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/bottom-clouds@1x.png);
  padding-top: 10.76%;
  background-size: 100% 100%;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .clouds {
    background-image: url(https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/bottom-clouds@2x.png);
  }
}
.page-listings {
  z-index: 1;
}
.page-listings__title.animated .char {
  -webkit-animation: slide-in 1s cubic-bezier(0.5, 0, 0.5, 1) both;
          animation: slide-in 1s cubic-bezier(0.5, 0, 0.5, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
          animation-delay: calc(60ms * var(--char-index));
}
.DetailsTitle h1,
.DetailsMainImage {
  background-color: #ea1d25;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: auto;
  display: inline-block;
  margin: 20px 5px 10px;
  float: none;
  width: 195px;
}
.footer__wrapper {
  background-color: #000;
  color: #fff;
  padding: 25px 10px;
  width: 100%;
  z-index: 1;
  position: relative;
}
@media (max-width: 767px) {
  .footer__wrapper {
    text-align: center;
  }
}
.footer__links {
  text-align: center;
}
@media (max-width: 767px) {
  .footer__links {
    text-align: center;
    margin-top: 15px;
  }
}
.footer__links a {
  color: white;
  margin-right: 10px;
}
.footer__BCN {
  display: block;
  max-width: 180px;
}
@media (max-width: 767px) {
  .footer__BCN {
    text-align: center;
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .footer__BCN {
    float: right;
  }
}
.footer__BCN img {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .footer__flex-row-sm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .footer__flex-row-sm p {
    margin-bottom: 0;
  }
}
#BouncyCastleHireLink {
  color: white;
  font-weight: 700;
}
.top-wrapper {
  padding-top: 1px;
}
.body-wrapper {
  min-height: 150vh;
}
.outer-wrapper {
  overflow: visible;
}
.outer-wrapper--alt .top-wrapper__starburst {
  top: 0;
  -webkit-transform: none;
          transform: none;
  padding-top: 0;
  background: none;
  overflow: hidden;
}
.outer-wrapper--alt .top-wrapper__starburst:before {
  content: "";
  display: block;
  top: 0;
  left: 0;
  padding-top: 100%;
  -webkit-transform: scale(2);
          transform: scale(2);
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/020/starburst.svg");
}
/*#region Utiliy Classes*/
.white {
  color: #fff;
}
.text-shadow-big {
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}
/*#endregion Utiliy Classes*/
.lower-content {
  background: #fff;
  border-radius: 10px;
  padding: 15px 20px;
  position: relative;
  z-index: 1;
}
.lower-content h2 {
  color: #e77c29;
  font-family: 'Bjola';
  font-size: 5vw;
  text-align: center;
}
@media (max-width: 767px) {
  .lower-content h2 {
    font-size: 2.5rem;
  }
}
@media (min-width: 1330px) {
  .lower-content h2 {
    font-size: 54px;
  }
}
/*#region Animations*/
.wow {
  visibility: hidden;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.fadeIn2 {
  -webkit-animation-name: fadeIn2;
          animation-name: fadeIn2;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown;
}
.animated.bounceIn {
  -webkit-animation-duration: 0.75s;
          animation-duration: 0.75s;
}
@-webkit-keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
.bounceIn {
  -webkit-animation-name: bounceIn;
          animation-name: bounceIn;
}
@-webkit-keyframes fadeIn2 {
  to {
    opacity: 0.2;
  }
}
@keyframes fadeIn2 {
  to {
    opacity: 0.2;
  }
}
@-webkit-keyframes slide-in {
  from {
    -webkit-transform: translateY(-1em) rotate(-0.5turn) scale(0.5);
            transform: translateY(-1em) rotate(-0.5turn) scale(0.5);
    opacity: 0;
  }
}
@keyframes slide-in {
  from {
    -webkit-transform: translateY(-1em) rotate(-0.5turn) scale(0.5);
            transform: translateY(-1em) rotate(-0.5turn) scale(0.5);
    opacity: 0;
  }
}
/*#endregion Animations*/
